import React from "react";
import HomePage from "./component/pages/HomePages";
import "./assets/css/bootstrap-theme.css"
import "./assets/css/bootstrap-theme.css.map"
import "./assets/css/bootstrap-theme.min.css"
import "./assets/css/bootstrap.css"
import "./assets/css/bootstrap.css.map"
import "./assets/css/bootstrap.min.css"
import "./assets/css/fontAwesome.css"
import "./assets/css/light-box.css"
import "./assets/css/templatemo-main.css"

const App = () => {
  return(
    <HomePage/>
  )
}

export default App